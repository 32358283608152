.title{
    font-weight: 800 !important;
    font-size: 20px !important;
    margin: 0 0 1.5em !important;
    border-bottom: 1px solid #ddd !important;
    padding-bottom: .5em !important;
}



.btn-success{
    border-radius: 0 !important;
    border-color: #548342 !important;
    background-color: #548342 !important;
}

.btn-outline-secondary{
    border-color: gray !important;
    background-color: transparent !important;
    color: gray !important;
}

.margin-top-30{
    margin-top: 30px !important;
}

.center-item{
    margin: auto;
}

.gray-text{
    color: rgb(204, 204, 204);
}

.card-white-gray{
    background-color: #f8f8f8 !important;
}

.min-height-300{
    min-height: 300px;
}

/*****************/

/* Table Forum */

table.content-table-users {
    border-collapse: collapse;
    margin: 2em 0;
    table-layout: auto;
    width: 100%;
}

.content-table-users tr:nth-of-type(odd) {
    text-align: left;
    vertical-align: top;
    color: #2A426D;
    font-size: 13px;
}

.button-download-document {
    font-weight: 700;
    border-radius: 50px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    background-color: #f2af2b;
    border: 1px solid #f2af2b;
    color: #fff;
    padding: .8em 10px;
    cursor: pointer;
}

.content-table-users td:nth-child(1) {
    padding-right: 1em
}

.content-table-users td:nth-child(1) p {
    margin: 0.2em 0;
}

.content-table-users tr {
    border-bottom: 1px solid #EBEBEB;
}

.content-table-users thead tr {
    border-bottom: 0;
}

.content-table-users th {
    padding-bottom: 1em;
}

.content-table-users td {
    padding: 1em 0;
    font-size: 16px;
}

.content-table-users .table-users--description {
    font-size: 13px;
    color: #222;
}

.content-table-users .table-users--title {
    font-weight: bold;
    font-size: 14px;
    color: #000;
}

.content-table-users .table-users--date {
    color: #9098AC !important;
    font-size: 12px !important;
    font-weight: bold;
}

.content-table-users .table-users--number {
    color: #E88B19;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.content-table-users .table-users--general{
    font-size: 14px;
    color: #222;
}

.content-table-users .list-library__aditional {
    font-weight: bold;
}

.selectTrello .css-1wa3eu0-placeholder {
    color: #222;
    font-size: 14px;
}

.selectTrello .css-1pahdxg-control, .selectTrello .css-yk16xz-control {
    border: 0;
    background: transparent;
    font-size: 13px;
    margin-bottom: .25em;
    border-bottom: 1px solid #d3d5db;
    border-radius: 0;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
    table.content-table-users .content-table-usersthead,
    .content-table-users tbody,
    .content-table-users th,
    .content-table-users td,
    .content-table-users tr {
        display: block;
    }

    .content-table-users td {
        padding: 0.25em 0
    }

    .content-table-users td:last-child {
        padding-bottom: 2em;
    }

    .content-table-users td:nth-child(1) {
        width: auto;
        padding-right: 1em
    }

    .content-table-users th {
        display: none;
    }
    .content-table-users thead {
        display: none;
    }
}


/* STYLE 26 May 2022 */
.reports-clear-card.card {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
}

.reports-clear-card.card .card-body {
    background-color: transparent;
    padding: 0;
}

.module-filter {
    padding: 0.25em 1em !important;
    background-color: #f8f8f8 !important;
}

.mt-negative {margin-top: -20px;}

.wraper-input {
    display: flex;
    justify-content: space-around;
}

.wraper-input__flexEnd {
    align-items: flex-end;
}

.wraper-input input {
    font-size: 15px;
    padding: 5px 10px 5px 0;
    margin: 0;
}

.wraper-input input:focus,
button:focus {
    outline: 0 !important
}

.wrapper-input__col {
    margin-right: 15px;
}

.wraper-input .button-secondary {
    width: 130px;
    background-color: transparent !important;
    color: #2A426D !important;
    border: 0 !important;
    font-weight: bold;
}

.wraper-input .button-secondary:hover {
    color: #9098AC !important;
}

.wraper-input .button-secondary svg {
    margin-right: 10px;
}

h6.title-filter {
    font-weight: bold;
    color: #666;
    font-size: 14px;
}

.wrapper-reports-btn-results {
    border-bottom: 1px solid #DDD;
    padding: 10px 0;
    margin-top: -25px;
}

.wrapper-reports-btn-results svg {
    margin-right: 8px;
    font-size: 14px;
    margin-top: -4px;
}

.wrapper-reports-btn-results button { padding-left: 20px; padding-right: 20px; margin-right: 10px}
.wrapper-reports-btn-results button:hover {
    background-color: #9098AC;
    border-color: #9098AC;
}

.filter-activity-teacher .wrapper-input__col {
    width: 50%;
}

.filter-activity-teacher .wrapper-input__col sup {
    display: block;
}

@media screen and (max-width: 600px) {
    .filter-activity-teacher.wraper-input {
        display: block;
    }
    .filter-activity-teacher .wrapper-input__col:nth-child(2) {
        margin-top: 25px;
    }

    .filter-activity-teacher .button-secondary {
        width: auto !important;
        margin-top: 20px;
    }
}